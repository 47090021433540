import Base from "../Base";
import vueOrganisationIntro from "./OrganisationIntro";
import vueOrganisationSummary from "./OrganisationSummary";
import vueMainOrganisationKvkRequest from "./MainOrganisation/MainOrganisationCreateFromKvk";
import vueOrganisationManuallyAdd from "./OrganisationCreateManually";
import vueMainOrganisationDetail from "./MainOrganisation/MainOrganisationDetail";
import vueMainOrganisationList from "./MainOrganisation/MainOrganisationList";
import vueChildOrganisationDetail from "./ChildOrganisation/ChildOrganisationDetail";
import vueChildOrganisationCreateFromKvk from "./ChildOrganisation/ChildOrganisationCreateFromKvk";
import vueChildOrganisationValidationChecks from "./ChildOrganisation/ChildOrganisationValidationChecks";
import vueChildOrganisationList from "./ChildOrganisation/ChildOrganisationList";
import store from "../../Store/store";

class Organisation extends Base {
    static getComponents() {
        return {
            "organisation-intro": vueOrganisationIntro,
            "organisation-summary": vueOrganisationSummary,
            "main-organisation-create-from-kvk": vueMainOrganisationKvkRequest,
            "organisation-manually-add": vueOrganisationManuallyAdd,
            "main-organisation-detail": vueMainOrganisationDetail,
            "main-organisation-list": vueMainOrganisationList,
            "child-organisation-create-from-kvk": vueChildOrganisationCreateFromKvk,
            "child-organisation-detail": vueChildOrganisationDetail,
            "child-organisation-validation-checks": vueChildOrganisationValidationChecks,
            "child-organisation-list": vueChildOrganisationList,
        };
    }

    static buildCreateOrganisationFromKvkForm(data) {
        return {
            kvk_number: data.kvk_number,
            branch: data.branch,
        };
    }
    static buildUpdateOrganisationForm(data) {
        return {
            phone_number: data.phone_number,
            email: data.email,
            website: data.website
        };
    }

    static buildCreateOrganisationWithManuallyAddedDataForm(data) {
        return {
            kvk_number: data.kvk_number,
            company_name: data.company_name,
            street_name: data.street_name,
            house_number: data.house_number,
            addition: data.addition,
            postal_code: data.postal_code,
            city: data.city,
            sbi_codes: data.sbi_codes,
            sbi_description: data.sbi_description,
            is_manually_added: true,
            branch: data.branch,
        };
    }

    static prepareCreateUploadFileRequestData(data) {
        return {
            logo: data.logo
        };
    }

    static createOrganisation(calculationToolId, payload) {
        return store.dispatch('organisation/create', {
            payload: payload,
            calculationToolId: calculationToolId
        }).then((response) => {
            return response.data;
        });
    }

    static getOrganisationsForCalculationTool(kvkNumber) {
        return store.dispatch('organisation/getOrganisationsForCalculationTool', kvkNumber).then((response) => {
            return response.data;
        });
    }

    static get(id) {
        return store.dispatch('organisation/get', {id: id});
    }

    static updateOrganisationLogo(organisationId, payload) {
        return store.dispatch('organisation/updateLogo', {organisationId: organisationId, payload: payload}).then((response) => {
            return response.data;
        });
    }

    static updateOrganisationBranch(organisationId, payload) {
        return store.dispatch('organisation/updateBranch', {organisationId: organisationId, payload: payload}).then((response) => {
            return response.data;
        });
    }

    static remove(calculationToolId, organisationId) {
        return store.dispatch('organisation/remove', {
            calculationToolId: calculationToolId,
            organisationId: organisationId
        });
    }

    static replaceMain(calculationToolId, organisationId) {
        return store.dispatch('organisation/replaceMain', {
            calculationToolId: calculationToolId,
            organisationId: organisationId
        });
    }

    static index(payload){
        return store.dispatch('organisation/index', payload);
    }

    static addOrganisationToCalculationTool(calculationToolId, organisationId, forceMain = false) {
        return store.dispatch('organisation/addToCalculationTool', {
            organisationId: organisationId,
            calculationToolId: calculationToolId,
            forceMain: forceMain
        }).then((response) => {
            return response.data;
        });
    }

    static updateOrganisation(organisationId, payload) {
        return store.dispatch('organisation/update', {organisationId: organisationId, payload: payload}).then((response) => {
            return response.data;
        });
    }

    static updateOrganisationContact(organisationId, payload) {
        return store.dispatch('organisation/updateContact', {organisationId: organisationId, payload: payload}).then((response) => {
            return response.data;
        });
    }
    static updateOrganisationByAuditor(organisationId, payload) {
        return store.dispatch('organisation/updateByAuditor', {organisationId: organisationId, payload: payload}).then((response) => {
            return response.data;
        });
    }

    static getBranches() {
        return store.dispatch('organisation/getBranches').then((response) => {
            return response.data;
        });
    }
}

export default Organisation;
