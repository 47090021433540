import CalculationToolData from "../Components/CalculationToolData/CalculationToolData";
import _ from "lodash";

class TargetGroupUpload {
    static uploadTargetGroupFile (files, calculationToolId, isAuditor = false) {
        const formData = new FormData();
        formData.append('target_group_file', files[0]);

        return CalculationToolData.uploadTargetGroup(calculationToolId, formData, isAuditor)
            .then(() => {
                window.mitt.emit('updateUploadStatus', {status: 'success'});
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    if (
                        typeof error.response.data.errors?.header !== 'undefined' ||
                        typeof error.response.data.errors?.target_group_file !== 'undefined'
                    ) {
                        window.mitt.emit('updateUploadStatus', {status: 'error_headings_or_extension'});
                    } else {
                        let count_duplicate_errors = 0;
                        const errors = error.response.data.errors.map(error => {
                            return {
                                row_number: error.row_number,
                                column: error.column,
                                message: error.errors[0],
                            };
                        });

                        let duplicateErrorMessages = [
                            'target_group_check_if_exists_in_file',
                            'target_group_check_if_exists_in_db',
                            'target_group_check_if_exists_in_db_in_file',
                        ];

                        _.each(errors, (error) => {
                            if (duplicateErrorMessages.includes(error.message)) {
                                count_duplicate_errors++;
                            }
                        });

                        if (Object.keys(errors).length === count_duplicate_errors) {
                            window.mitt.emit('updateUploadStatus', {
                                status: 'error_duplicates',
                                error: errors
                            });
                        } else if (count_duplicate_errors > 0) {
                            window.mitt.emit('updateUploadStatus', {
                                status: 'error_duplicates_and_rows',
                                error: errors
                            });
                        } else {
                            window.mitt.emit('updateUploadStatus', {
                                status: 'error_rows',
                                error: errors
                            });
                        }
                    }
                }
                if (error.response.status === 404) {
                    window.mitt.emit('updateUploadStatus', {status: 'server_error'});
                }
                if (error.response.status === 500) {
                    window.mitt.emit('updateUploadStatus', {status: 'server_error'});
                }
            });
    }
}

export default TargetGroupUpload;
