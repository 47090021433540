import Base from "../Base";
import store from "../../Store/store"
import vueRegister from "./Parts/Register";
import vueLogin from "./Parts/Login";
import vueForgotPassword from "./Parts/ForgotPassword";
import vueResetPassword from "./Parts/ResetPassword";
import vueSelectFactorAuthForm from "./Parts/SelectTwoFactorAuth";
import vueTwoFactorEnterCodeForm from "./Parts/TwoFactorAuthEnterCodeForm";
import vueVerificationExpired from "./Parts/VerificationExpired";

class User extends Base {
    static getComponents() {
        return {
            "register-form": vueRegister,
            "login-form": vueLogin,
            "forgot-password": vueForgotPassword,
            "reset-password": vueResetPassword,
            "select-two-factor-auth-form": vueSelectFactorAuthForm,
            "two-factor-auth-enter-code-form": vueTwoFactorEnterCodeForm,
            "verification-expired": vueVerificationExpired,
        }
    }

    static buildRegisterForm(data) {
        return {
            organisation: data.organisation,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
            initials: data.initials,
            first_name: data.first_name,
            infix: data.infix,
            last_name: data.last_name,
            agree_terms_conditions: data.agree_terms_conditions,
            agree_TNO_contact: data.agree_TNO_contact,
            agree_newsletter: data.agree_newsletter
        };
    }

    static buildLoginForm(data) {
        return {
            email: data.email,
            password: data.password
        };
    }

    static buildForgotPasswordForm(data) {
        return {
            email: data.email
        };
    }

    static buildResetPasswordForm(data) {
        return {
            token: data.token,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation
        };
    }

    static buildSelectTwoFactorAuthForm(data) {
        return {
            type: data.type
        };
    }

    static buildEnterTwoFactorAuthCodeForm(data) {
        return {
            digit_one: data.digit_one,
            digit_two: data.digit_two,
            digit_three: data.digit_three,
            digit_four: data.digit_four,
            digit_five: data.digit_five,
            digit_six: data.digit_six,
            remember_me: data.remember_me,
        };
    }

    static register(data) {
        return store.dispatch('user/register', data).then((response) => {
            return response.data;
        });
    }

    static login(data) {
        return store.dispatch('user/login', data).then((response) => {
            return response.data;
        });
    }

    static getRoles() {
        return store.dispatch('user/getRoles');
    }

    /**
     * This will send the reset password link
     *
     * @returns {Promise<any>}
     */
    static forgotPasswordEmail(data) {
        return store.dispatch('user/sendForgotPasswordLink', data).then((response) => {
            return response.data;
        });
    }

    /**
     * This will resend the verificiation email so the user can activate their account
     *
     * @param data
     * @returns {Promise<any>}
     */
    static resendVerification(data) {
        return store.dispatch('user/resendVerification', data).then((response) => {
            return response.data;
        });
    }

    /**
     * This will save the new password to the database
     *
     * @returns {Promise<any>}
     */
    static resetPasswordSave(data) {
        return store.dispatch('user/saveResetPassword', data).then((response) => {
            return response.data;
        });
    }

    /**
     * This will save to the DB whether the user selected the GoogleAuth or the Email 2FA system
     *
     * @returns {Promise<any>}
     */
    static saveSelectedTwoFactorAuth(data) {
        return store.dispatch('user/saveSelectedTwoFactorAuth', data).then((response) => {
            return response.data;
        });
    }

    /**
     * This will Submit the 2FA code
     *
     * @returns {Promise<any>}
     */
    static checkTwoFactorAuthCode(data) {
        return store.dispatch('user/checkTwoFactorAuthCode', data).then((response) => {
            return response.data;
        });
    }
}

export default User;
