<template>
    <div class="page organisation-index">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto rounded-xl p-12 bg-white shadow">
                <div class="actions-top">
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>

                <h2 class="mb-10 text-pso-dark text-2xl text-center font-bold">
                    {{ translate('calculation_tool_data.determine_company_size.title') }}
                </h2>

                <div class="introduction-section">
                    <h5 class="text-lg text-pso-dark font-medium">
                        {{ translate('calculation_tool_data.determine_company_size.intro_section.visible.heading') }}
                    </h5>
                    <p class="text-lg mb-8">
                        {{ translate('calculation_tool_data.determine_company_size.intro_section.visible.paragraph') }}
                    </p>

                    <app-show-more>
                        <h5 class="text-lg text-pso-dark font-medium">
                            {{
                                translate('calculation_tool_data.determine_company_size.intro_section.hidden.heading')
                            }}
                        </h5>
                        <p class="mb-8 text-lg">
                            {{
                                translate('calculation_tool_data.determine_company_size.intro_section.hidden.paragraph_one')
                            }}
                        </p>

                        <ul class="list-disc mb-8 pl-6">
                            <li class="text-lg">
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_one.part_one')
                                }}
                                <a
                                    :href="links.example_calculation_guide_step_3b"
                                    class="text-pso-primary"
                                >
                                    {{
                                        translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_one.first_link')
                                    }}
                                </a>
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_one.part_two')
                                }}
                                <a
                                    :href="links.manual_pso"
                                    class="text-pso-primary"
                                >
                                    {{
                                        translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_one.second_link')
                                    }}
                                </a>
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_one.part_three')
                                }}
                            </li>
                        </ul>

                        <p class="text-lg">
                            {{
                                translate('calculation_tool_data.determine_company_size.intro_section.hidden.paragraph_two')
                            }}
                        </p>
                        <ul class="list-disc pl-6 mb-3">
                            <li class="text-lg">
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_two')
                                }}
                            </li>
                            <li class="text-lg">
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_three')
                                }}
                            </li>
                            <li class="text-lg">
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_four')
                                }}
                            </li>
                            <li class="text-lg">
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_five')
                                }}
                            </li>
                            <li class="text-lg">
                                {{
                                    translate('calculation_tool_data.determine_company_size.intro_section.hidden.bullet_six')
                                }}
                            </li>
                        </ul>
                    </app-show-more>

                    <div class="blue-separator h-0.5 bg-blue-400 my-[44px]" />

                    <div class="gray-section border border-pso-dark rounded-lg drop-shadow-lg mb-10 p-8">
                        <h4 class="heading mb-6 text-pso-dark text-xl font-medium">
                            {{ translate('calculation_tool_data.determine_company_size.gray_section.title') }}
                        </h4>

                        <div class="info-line flex items-center mb-4">
                            <div class="w-[165px] text-pso-dark text-md">
                                {{
                                    translate('calculation_tool_data.determine_company_size.gray_section.reference_year')
                                }}
                            </div>
                            <div class="text-pso-dark text-lg">
                                {{ calculationToolData.startDate }}
                                {{
                                    translate('calculation_tool_data.determine_company_size.gray_section.reference_year_tm')
                                }}
                                {{ calculationToolData.referenceDate }}
                            </div>
                        </div>
                        <div class="info-line flex items-center">
                            <div class="w-[165px] text-pso-dark text-md">
                                {{ translate('calculation_tool_data.determine_company_size.gray_section.cao') }}
                            </div>
                            <div class="text-pso-dark text-lg">
                                {{ calculationToolData.cao }}
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="initialized"
                        class="form-section mb-10"
                    >
                        <p class="mb-8 text-lg text-pso-dark">
                            {{ translate('calculation_tool_data.determine_company_size.form_part_one.paragraph') }}
                        </p>

                        <div class="w-full mb-8 flex">
                            <div class="w-1/2 mr-4">
                                <label
                                    for="employees_a"
                                    class="block mb-2 text-lg text-pso-dark"
                                >
                                    {{ translate('calculation_tool_data.determine_company_size.form_part_one.field_label_one') }}
                                </label>

                                <NumberInput
                                    id="employees_a"
                                    v-model:model-value="form.employees_a"
                                    name="employees_a"
                                    :has-decimals="true"
                                />

                                <span
                                    v-if="errors.employees_a"
                                    dusk="employees-a-validation"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.employees_a"
                                />
                            </div>

                            <div class="w-1/2">
                                <label
                                    for="fte_g"
                                    class="block mb-2 text-lg text-pso-dark"
                                >
                                    {{ translate('calculation_tool_data.determine_company_size.form_part_one.field_label_two') }}
                                </label>

                                <NumberInput
                                    id="fte_g"
                                    v-model:model-value="form.fte_g"
                                    name="fte_g"
                                    :step="0.1"
                                    :has-decimals="true"
                                />

                                <span
                                    v-if="errors.fte_g"
                                    dusk="fte-g-validation"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.fte_g"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="initialized"
                        class="form-section mb-10"
                    >
                        <p class="mb-8 text-lg text-pso-dark">
                            {{ translate('calculation_tool_data.determine_company_size.form_part_two.paragraph') }}
                        </p>

                        <div class="w-full mb-8 flex">
                            <div class="w-1/2 mr-4">
                                <label
                                    for="employees_b"
                                    class="block mb-2 text-lg text-pso-dark"
                                >
                                    {{ translate('calculation_tool_data.determine_company_size.form_part_two.field_label_one') }}
                                </label>

                                <NumberInput
                                    id="employees_b"
                                    v-model:model-value="form.employees_b"
                                    name="employees_b"
                                    :has-decimals="true"
                                />

                                <span
                                    v-if="errors.employees_b"
                                    dusk="employees-b-validation"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.employees_b"
                                />
                            </div>

                            <div class="w-1/2">
                                <label
                                    for="fte_h"
                                    class="block mb-2 text-lg text-pso-dark"
                                >
                                    {{ translate('calculation_tool_data.determine_company_size.form_part_two.field_label_two') }}
                                </label>

                                <NumberInput
                                    id="fte_h"
                                    v-model:model-value="form.fte_h"
                                    name="fte_h"
                                    :step="0.1"
                                    :has-decimals="true"
                                />

                                <span
                                    v-if="errors.fte_h"
                                    dusk="fte-h-validation"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.fte_h"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="showInternsInputs === false"
                        class="wizard-adding-interns"
                    >
                        <div
                            class="wizard-body mt-10 mb-10"
                        >
                            <p class="text-pso-dark text-lg">
                                {{ translate('calculation_tool_data.determine_company_size.question_adding_interns') }}
                            </p>
                            <div
                                class="flex justify-center mt-6"
                            >
                                <div
                                    class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl mr-4"
                                    :class="confirmationAddingInterns === '1' ? 'border-pso-primary bg-pso-primary' : 'border-gray-200'"
                                >
                                    <label
                                        for="confirmationAddingInternsYes"
                                        class="text-md items-center mb-0 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                                        :class="confirmationAddingInterns === '1' ? 'text-white' : 'text-black'"
                                    >{{ translate('layout.wizard.yes') }}</label>
                                    <input
                                        id="confirmationAddingInternsYes"
                                        v-model="confirmationAddingInterns"
                                        type="radio"
                                        class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-pso-dark checked:border-pso-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                        name="confirmationAddingInterns"
                                        value="1"
                                        dusk="confirmation-adding-interns-yes"
                                    >
                                </div>

                                <div
                                    class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl"
                                    :class="confirmationAddingInterns === '0' ? 'border-pso-primary bg-pso-primary' : 'border-gray-200'"
                                >
                                    <label
                                        for="confirmationAddingInternsNo"
                                        class="text-md items-center mb-0 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                                        :class="confirmationAddingInterns === '0' ? 'text-white' : 'text-black'"
                                    >
                                        {{ translate('layout.wizard.no') }}
                                    </label>
                                    <input
                                        id="confirmationAddingInternsNo"
                                        v-model="confirmationAddingInterns"
                                        type="radio"
                                        class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-pso-dark checked:border-pso-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                        name="confirmationAddingInterns"
                                        value="0"
                                        dusk="confirmation-adding-interns-no"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="actions-bottom">
                            <div class="action-button text-center">
                                <button
                                    class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                                    :class="confirmationAddingInterns ? '' : 'opacity-50 cursor-not-allowed'"
                                    dusk="wizard-adding-interns-btn"
                                    @click.prevent="showInternsForm()"
                                >
                                    {{ translate('calculation_tool_data.button_next_step') }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="showInternsInputs === true && initialized"
                        class="wizard-interns"
                    >
                        <div
                            class="wizard-body mt-10 mb-10"
                        >
                            <p class="text-pso-dark text-lg">
                                {{ translate('calculation_tool_data.determine_company_size.message_adding_interns_1') }}
                            </p>
                            <p class="text-pso-dark text-lg mt-6 mb-8">
                                {{ translate('calculation_tool_data.determine_company_size.message_adding_interns_2') }}
                            </p>
                            <div class="form-section mb-10">
                                <div class="w-full mb-8 flex">
                                    <div class="w-1/2 mr-4">
                                        <label
                                            for="employees_c"
                                            class="block mb-2 text-lg text-pso-dark"
                                        >
                                            {{ translate('calculation_tool_data.determine_company_size.form_part_three.field_label_one') }}
                                        </label>
                                        
                                        <NumberInput
                                            id="employees_c"
                                            v-model:model-value="form.employees_c"
                                            name="employees_c"
                                            :has-decimals="true"
                                        />

                                        <span
                                            v-if="errors.employees_c"
                                            dusk="employees-c-validation"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.employees_c"
                                        />
                                    </div>

                                    <div class="w-1/2">
                                        <label
                                            for="fte_i"
                                            class="block mb-2 text-lg text-pso-dark"
                                        >
                                            {{ translate('calculation_tool_data.determine_company_size.form_part_three.field_label_two') }}
                                        </label>

                                        <NumberInput
                                            id="fte_i"
                                            v-model:model-value="form.fte_i"
                                            name="fte_i"
                                            :step="0.1"
                                            :has-decimals="true"
                                        />

                                        <span
                                            v-if="errors.fte_i"
                                            dusk="fte-i-validation"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.fte_i"
                                        />
                                    </div>
                                </div>
                            </div>


                            <p
                                v-if="showSWCompanyInputs === false"
                                class="text-pso-dark text-lg mt-6 text-center"
                            >
                                {{ translate('calculation_tool_data.determine_company_size.question_sw_company') }}
                            </p>
                            <div
                                v-if="showSWCompanyInputs === false"
                                class="flex justify-center mt-6"
                            >
                                <div
                                    class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl mr-4"
                                    :class="confirmationSWCompany === '1' ? 'border-pso-dark bg-pso-primary' : 'border-gray-200'"
                                >
                                    <label
                                        for="confirmationSWCompanyYes"
                                        class="text-md items-center mb-0 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                                        :class="confirmationSWCompany === '1' ? 'text-white' : 'text-black'"
                                    >{{ translate('layout.wizard.yes') }}</label>
                                    <input
                                        id="confirmationSWCompanyYes"
                                        v-model="confirmationSWCompany"
                                        type="radio"
                                        class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-pso-dark checked:border-pso-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                        name="confirmationSWCompany"
                                        value="1"
                                        dusk="confirmation-sw-company-yes"
                                    >
                                </div>

                                <div
                                    class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl"
                                    :class="confirmationSWCompany === '0' ? 'border-pso-dark bg-pso-primary' : 'border-gray-200'"
                                >
                                    <label
                                        for="confirmationSWCompanyNo"
                                        class="text-md text-black items-center mb-0 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                                        :class="confirmationSWCompany === '0' ? 'text-white' : 'text-black'"
                                    >
                                        {{ translate('layout.wizard.no') }}
                                    </label>
                                    <input
                                        id="confirmationSWCompanyNo"
                                        v-model="confirmationSWCompany"
                                        type="radio"
                                        class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-pso-dark checked:border-pso-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                                        name="confirmationSWCompany"
                                        value="0"
                                        dusk="confirmation-sw-company-no"
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="showSWCompanyInputs === false"
                            class="actions-bottom"
                        >
                            <div class="action-button text-center">
                                <button
                                    class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                                    :class="confirmationSWCompany ? '' : 'opacity-50 cursor-not-allowed'"
                                    dusk="wizard-adding-sw-company-btn"
                                    @click.prevent="showSWCompanyForm()"
                                >
                                    {{ translate('calculation_tool_data.button_next_step') }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="showSWCompanyInputs === true && initialized"
                        class="wizard-sw-company"
                    >
                        <div
                            class="wizard-body mt-10 mb-10"
                        >
                            <p class="text-pso-dark text-lg">
                                {{ translate('calculation_tool_data.determine_company_size.paragraph_sw_company') }}
                            </p>

                            <div class="form-section mb-10 mt-10">
                                <div class="w-full mb-8 flex">
                                    <div class="w-1/2 mr-4">
                                        <label
                                            for="employees_d"
                                            class="block mb-2 text-lg text-pso-dark"
                                        >
                                            {{ translate('calculation_tool_data.determine_company_size.form_part_four.field_label_one') }}
                                        </label>

                                        <NumberInput
                                            v-model:model-value="form.employees_d"
                                            name="employees_d"
                                            id="employees_d"
                                            :has-decimals="true"
                                        />

                                        <span
                                            v-if="errors.employees_d"
                                            dusk="employees-d-validation"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.employees_d"
                                        />
                                    </div>

                                    <div class="w-1/2">
                                        <label
                                            for="fte_j"
                                            class="block mb-2 text-lg text-pso-dark"
                                        >
                                            {{ translate('calculation_tool_data.determine_company_size.form_part_four.field_label_two') }}
                                        </label>

                                        <NumberInput
                                            v-model:model-value="form.fte_j"
                                            name="fte_j"
                                            id="fte_j"
                                            :step="0.1"
                                            :has-decimals="true"
                                        />

                                        <span
                                            v-if="errors.fte_j"
                                            dusk="fte-j-validation"
                                            class="text-sm text-red-500"
                                            role="alert"
                                            v-text="errors.fte_j"
                                        />
                                    </div>
                                </div>
                            </div>

                            <p class="text-pso-dark text-lg">
                                {{ translate('calculation_tool_data.determine_company_size.paragraph_calculation') }}
                            </p>

                            <div class="form-section mb-10 mt-10">
                                <div class="w-full mb-8 flex">
                                    <div class="w-1/2 mr-4">
                                        <label
                                            for="employees_e"
                                            class="block mb-2 text-lg text-pso-dark"
                                        >
                                            {{ translate('calculation_tool_data.determine_company_size.form_part_five.field_label_one') }}
                                        </label>

                                        <div class="col-md-6">
                                            <input
                                                id="employees_e"
                                                v-model="form.employees_e"
                                                type="text"
                                                class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-lg placeholder:lowercase placeholder:text-gray-600 bg-[#F5F5F5] text-[#393C3F]"
                                                :class="[(errors.employees_e ? 'border-red-500' : '')]"
                                                name="employees_e"
                                                maxlength="10"
                                                autocomplete="employees_e"
                                                dusk="employees-e-input"
                                                disabled
                                                readonly
                                            >

                                            <span
                                                v-if="errors.employees_e"
                                                dusk="employees-e-validation"
                                                class="text-sm text-red-500"
                                                role="alert"
                                                v-text="errors.employees_e"
                                            />
                                        </div>
                                    </div>

                                    <div class="w-1/2">
                                        <label
                                            for="fte_k"
                                            class="block mb-2 text-lg text-pso-dark"
                                        >
                                            {{ translate('calculation_tool_data.determine_company_size.form_part_five.field_label_two') }}
                                        </label>

                                        <div class="col-md-6">
                                            <input
                                                id="fte_k"
                                                v-model="form.fte_k"
                                                type="text"
                                                class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-lg placeholder:lowercase placeholder:text-gray-600 bg-[#F5F5F5] text-[#393C3F]"
                                                :class="[(errors.fte_k ? 'border-red-500' : '')]"
                                                name="fte_k"
                                                autocomplete="fte_k"
                                                disabled
                                                readonly
                                            >

                                            <span
                                                v-if="errors.fte_k"
                                                dusk="fte-k-validation"
                                                class="text-sm text-red-500"
                                                role="alert"
                                                v-text="errors.fte_k"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="actions-bottom">
                            <div class="action-button text-center">
                                <button
                                    class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                                    @click.prevent="nextStep()"
                                >
                                    {{
                                        translate('calculation_tool_data.determine_company_size.button_add_target_group')
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import CalculationTool from "../../../CalculationTool/CalculationTool";
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import ShowMoreElement from '../../../Templates/Parts/ShowMoreElement/Vue/ShowMoreElement.vue';
import CalculationToolData from "../../../CalculationToolData/CalculationToolData";
import dayjs from 'dayjs';
import Links from "../../../_config/Links.js";
import User from "../../../User/User";
import {getUserRoles} from "../../../_config/Roles";
import * as Views from "../../../_config/Views";
import {getSteps} from "../../../_config/Steps"
import NumberInput from "../../../FormInput/NumberInput/Vue/NumberInput.vue";

export default {
    components: {
        NumberInput,
        CalculationToolHeader,
        'app-back-button': BackLinkButton,
        'app-show-more': ShowMoreElement,
    },
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            links: Links,
            introIsExpanded: false,
            toggleMoreInfoText: this.translate('organisation.summary.more_info'),
            calculationToolData: {
                referenceDate: 'N/A',
                startDate: 'N/A',
                cao: 'N/A',
            },
            form: {
                employees_a: null,
                employees_b: null,
                employees_c: null,
                employees_d: null,
                employees_e: 0,
                fte_g: null,
                fte_h: null,
                fte_i: null,
                fte_j: null,
                fte_k: 0,
            },
            auditorExtraForm: {
                confirm_correctly_filled_in: null,
                first_explanation: null,
                choice: null,
                second_explanation: null,
                view: Views.getViews().determine_company_size
            },
            errors: {},
            confirmationAddingInterns: '',
            confirmationSWCompany: '',
            showInternsInputs: false,
            showSWCompanyInputs: false,
            initialized: false,
            isAuditor: false,
        };
    },

    computed: {
        ...mapGetters("calculation_tool", ["calculation_tool"]),
        ...mapGetters("calculation_tool_data", ["calculation_tool_data"]),
        ...mapGetters("auditor", ["extra_inputs"]),
        ...mapGetters("user", ["roles"]),
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.calculateTotal();
            }
        },
        roles: {
            handler(data) {
                if (data.roles.includes(getUserRoles().auditor)) {
                    this.isAuditor = true;
                }
            },
        },
    },
    created() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationToolData.get(this.calculationToolIdProp).then(() => {
            this.calculationToolData.referenceDate = dayjs(this.calculation_tool_data.reference_date).format('DD-MM-YYYY');
            this.calculationToolData.startDate = dayjs(this.calculation_tool_data.reference_date).subtract(1, 'year').format('DD-MM-YYYY');
            this.calculationToolData.cao = this.calculation_tool_data.cao_hours;

            this.form.employees_a = this.calculation_tool_data.employees_a ?? '0.00';
            this.form.employees_b = this.calculation_tool_data.employees_b ?? '0.00';
            this.form.employees_c = this.calculation_tool_data.employees_c ?? null;
            this.form.employees_d = this.calculation_tool_data.employees_d ?? null;
            this.form.employees_e = this.calculation_tool_data.employees_e ?? 0;
            this.form.fte_g = this.calculation_tool_data.fte_g ?? '0.00';
            this.form.fte_h = this.calculation_tool_data.fte_h ?? '0.00';
            this.form.fte_i = this.calculation_tool_data.fte_i ?? null;
            this.form.fte_j = this.calculation_tool_data.fte_j ?? null;
            this.form.fte_k = this.calculation_tool_data.fte_k ?? 0;

            if (this.form.employees_c !== null || this.form.fte_i !== null) {
                this.showInternsInputs = true;
            }
            if (this.form.employees_d !== null || this.form.fte_j !== null) {
                this.showSWCompanyInputs = true;
            }

            this.initialized = true;
        });
        User.getRoles();
    },
    methods: {
        previousStep() {
            if (this.showSWCompanyInputs) {
                this.showSWCompanyInputs = false;
                this.form.employees_d = null;
                this.form.fte_j = null;
            } else {
                if (this.showInternsInputs) {
                    this.showInternsInputs = false;
                    this.form.employees_c = null;
                    this.form.fte_i = null;
                } else {
                    window.location = route('calculation-tool-data.reference-date-cao-hours', {
                        calculationTool: this.calculationToolIdProp,
                    });
                }
            }
        },
        showInternsForm() {
            this.form.fte_i = '0.00';
            this.form.employees_c = '0.00';
            if (this.confirmationAddingInterns === '0') {
                this.calculateTotal();
            }
            if (this.confirmationAddingInterns !== '') {
                this.showInternsInputs = true;
            }
        },
        showSWCompanyForm() {
            this.form.fte_j = '0.00';
            this.form.employees_d = '0.00';
            if (this.confirmationSWCompany === '0') {
                this.calculateTotal();
            }
            if (this.confirmationSWCompany !== '') {
                this.showSWCompanyInputs = true;
            }
        },
        calculateTotal() {
            const totalEmployees = this.toFloat(this.form.employees_a) + this.toFloat(this.form.employees_b) + this.toFloat(this.form.employees_c) - this.toFloat(this.form.employees_d) || 0.00;
            const totalFte = this.toFloat(this.form.fte_g) + this.toFloat(this.form.fte_h) + this.toFloat(this.form.fte_i) - this.toFloat(this.form.fte_j) || 0.00;
            this.form.employees_e = this.toDutchFormat(totalEmployees);
            this.form.fte_k = this.toDutchFormat(totalFte);
        },
        nextStep() {
            this.postCalculationToolDataForm();
        },
        postCalculationToolDataForm() {
            this.calculateTotal();

            let formData = CalculationToolData.buildCompanySizeDataForm(this.form);
            CalculationToolData.update(this.calculationToolIdProp, formData)
                .then((data) => {
                    if (typeof data.data.calculation_tool_data === 'undefined') {
                        throw new Error('Calculation Tool Data ID is missing');
                    }

                    CalculationTool.updateStep({ calculationTool: this.calculationToolIdProp, step: getSteps().target_group_data })
                        .then((response) => {
                            if (response.status === 200) {
                                window.location.href = route('calculation-tool-data.target-group-data', { calculationTool: this.calculationToolIdProp });
                            }
                        }).catch((error) => {
                            console.log(error.response.data.data.message);
                        });
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        this.$setErrorsFromResponse(error.response.data);
                    }
                });
        },
        toFloat(value) {
            if (typeof value === 'number') {
                return value;
            }

            value = value || '0';
            return parseFloat(value.replace(',', '.'));
        },
        toDutchFormat(value) {
            return parseFloat(value).toFixed(2).replace('.', ',');
        },
    },
};
</script>
