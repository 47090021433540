<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button @click.prevent="previousStep()" />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('calculation_tool_data.purchase.title_1') }}
                    <br>
                    {{ translate('calculation_tool_data.purchase.title_2') }}
                </h2>

                <h5 class="text-lg text-pso-dark font-medium">
                    {{ translate('calculation_tool_data.purchase.paragraph_1_title') }}
                </h5>
                <p
                    class="text-pso-dark text-lg"
                >
                    {{ translate('calculation_tool_data.purchase.paragraph_1') }} <br><br>
                    <p class="font-medium">{{ translate('calculation_tool_data.purchase.paragraph_1_sub') }}</p>
                </p>

                <button
                    class="my-4 py-2 min-w-[136px] text-center text-white bg-pso-orange rounded-lg"
                    @click="downloadCertifiedOrganisations"
                >
                    Download
                </button>

                <app-show-more>
                    <h5 class="text-lg font-medium">
                        {{ translate('calculation_tool_data.purchase.paragraph_2_title') }}
                    </h5>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.paragraph_2') }}
                    </p>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.paragraph_2_1') }}
                    </p>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.paragraph_2_2') }}
                    </p>

                    <h5 class="text-lg font-medium">
                        {{ translate('calculation_tool_data.purchase.paragraph_3_title') }}
                    </h5>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.paragraph_3') }}
                    </p>


                    <ul class="list-disc pl-6 text-pso-dark text-lg mb-8">
                        <li>{{ translate('calculation_tool_data.purchase.paragraph_3_bullet_1') }}</li>
                        <li>{{ translate('calculation_tool_data.purchase.paragraph_3_bullet_2') }}</li>
                    </ul>

                    <h5 class="text-lg font-medium">
                        {{ translate('calculation_tool_data.purchase.paragraph_4_title') }}
                    </h5>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.paragraph_4') }}
                    </p>

                    <ul class="list-disc pl-6 text-pso-dark text-lg mb-8">
                        <li>
                            {{ translate('calculation_tool_data.purchase.paragraph_4_bullet_1_1') }}
                            <a
                                class="text-pso-primary"
                                :href="links.manual_pso"
                                target="_blank"
                            >
                                {{ translate('calculation_tool_data.purchase.paragraph_4_bullet_1_link') }}
                            </a>.
                            {{ translate('calculation_tool_data.purchase.paragraph_4_bullet_1_2') }}
                        </li>
                        <li>{{ translate('calculation_tool_data.purchase.paragraph_4_bullet_2') }}</li>
                    </ul>

                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.bellow_paragraph_1') }}
                    </p>
                    <p
                        class="text-pso-dark text-lg mb-8"
                    >
                        {{ translate('calculation_tool_data.purchase.bellow_paragraph_2_1') }}
                        <a
                            class="text-pso-primary"
                            :href="links.handbook_letter_more_social_procurement_with_the_pso_quality_mark"
                            target="_blank"
                        >{{ translate('calculation_tool_data.purchase.bellow_paragraph_2_link_1') }}
                        </a>
                        {{ translate('calculation_tool_data.purchase.bellow_paragraph_2_2') }}
                        <a
                            class="text-pso-primary"
                            :href="links.handbook_letter_more_social_procurement_with_the_pso_quality_mark"
                            target="_blank"
                        >{{ translate('calculation_tool_data.purchase.bellow_paragraph_2_link_2') }}
                        </a>.
                    </p>
                </app-show-more>

                <div class="blue-separator h-0.5 bg-blue-400 my-[44px]" />

                <FilledInDetailsBlock :calculation-tool-data-prop="calculationToolData" />

                <div class="mt-12 mb-8">
                    <div class="w-full flex">
                        <div class="w-2/3 text-lg">
                            {{ translate('calculation_tool_data.purchase.switch_tabs') }}
                        </div>
                        <div class="w-1/3 text-right">
                            <div class="tabs-select bg-pso-dark rounded-lg inline-block">
                                <button
                                    :class="showTab === 'manual-input' ? 'bg-white text-pso-dark font-medium border rounded-lg' : 'text-white'"
                                    class="py-2 min-w-[106px] text-center"
                                    @click.prevent="switchTabs('manual-input')"
                                >
                                    {{ translate('calculation_tool_data.purchase.manual_input_btn') }}
                                </button>
                                <button
                                    :class="showTab === 'upload-excel' ? 'bg-white text-pso-dark font-medium border rounded-lg' : 'text-white'"
                                    class="py-2 min-w-[106px] text-center"
                                    dusk="excel-tab-btn"
                                    @click.prevent="switchTabs('upload-excel')"
                                >
                                    {{ translate('calculation_tool_data.purchase.excel_btn') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showTab === 'manual-input'"
                    class="manual-input mb-8"
                />
                <div
                    v-if="showTab === 'upload-excel'"
                    class="upload-excel mb-8"
                >
                    <h2 class="font-medium text-xl mb-6">
                        {{ translate('calculation_tool_data.purchase.excel_upload_title') }}
                    </h2>
                    <p class="mb-2">
                        {{
                            translate('calculation_tool_data.purchase.excel_upload_description')
                        }}
                    </p>
                    <ul class="list-disc pl-6 text-pso-dark text-lg mb-8">
                        <li> {{ translate('calculation_tool_data.purchase.excel_upload_description_1') }}</li>
                        <li> {{ translate('calculation_tool_data.purchase.excel_upload_description_2') }}</li>
                        <li> {{ translate('calculation_tool_data.purchase.excel_upload_description_3') }}</li>
                    </ul>
                    <a
                        :href="demoExcelFile"
                        class="inline-flex mx-auto py-4 px-6 rounded-xl text-white bg-pso-dark"
                    >
                        <img
                            :src="iconDownload"
                            alt="download icon"
                            class="mr-2"
                        >
                        {{ translate('calculation_tool_data.purchase.excel_download') }}
                    </a>
                    <div
                        v-if="uploadStatus === ''"
                        class="mt-6 bg-gray-200 text-center text-gray-600 rounded-xl drop-zone"
                    >
                        <div>
                            <div v-bind="getRootProps()">
                                <input v-bind="getInputProps()">
                                <p
                                    v-if="isDragActive"
                                    class="text-gray-600 pt-6 pl-6 pr-6"
                                >
                                    {{ translate('calculation_tool_data.purchase.excel_drag_and_drop_here') }}
                                </p>
                                <p
                                    v-else
                                    class="text-gray-600 pt-6 pl-6 pr-6"
                                >
                                    {{ translate('calculation_tool_data.purchase.excel_drag_and_drop') }}
                                </p>
                                <img
                                    :src="iconUploadRound"
                                    alt=""
                                    class="pt-4 pb-6 pl-6 pr-6 m-auto cursor-pointer"
                                >
                            </div>
                        </div>
                    </div>

                    <success-alert
                        :id-prop="'upload-excel'"
                        class="mt-6"
                    />

                    <error-alert
                        :id-prop="'upload-excel'"
                        class="mt-6"
                    />
                </div>
                <div
                    v-if="showTab === 'manual-input'"
                    class="manual-input mb-8"
                >
                    <h4 class="heading mb-6 text-pso-dark text-xl font-medium">
                        {{ translate('calculation_tool_data.purchase.manually_add.purchase_form_title') }}
                    </h4>
                    <div class="w-full border border-gray-200 rounded-xl p-6">
                        <div class="mb-6">
                            <label
                                class="block mb-2 text-lg text-pso-dark"
                                dusk="label-product-name"
                                for="label-product-name"
                            >
                                {{ translate('calculation_tool_data.purchase.manually_add.product_name_label') }}
                            </label>

                            <input
                                id="label-product-name"
                                v-model="form.product_name"
                                :class="{'border-red-500': errors.product_name}"
                                :placeholder="translate('calculation_tool_data.purchase.manually_add.product_name_placeholder')"
                                autocomplete="product_name"
                                class="w-1/2 form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600 block"
                                name="product_name"
                                type="text"
                                @keyup="validateRequiredFields"
                            >

                            <span
                                v-if="errors.product_name"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.product_name"
                            />
                        </div>
                        <div class="mb-6">
                            <div
                                :class="{'pb-6':errors.kvk}"
                                class="pb-2"
                                dusk="label-certified-organisation"
                            >
                                <span class="text-lg text-pso-dark inline-block">
                                    {{
                                        translate('calculation_tool_data.purchase.manually_add.certified_organisation_label')
                                    }}
                                </span>
                            </div>
                            <div
                                class="w-full"
                            >
                                <Multiselect
                                    v-model="form.organisationId"
                                    :can-clear="true"
                                    :searchable="true"
                                    :classes="{
                                        caret: 'icon_chevron_down bg-no-repeat w-[22px] h-4 z-10 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
                                        placeholder: 'flex items-center h-full absolute left-2.5 italic font-[system-ui] top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-[#AEB4B8] rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                                    }"
                                    :options="certifiedOrganisationsOptions"
                                    :groups="true"
                                    group-label="label"
                                    group-values="options"
                                    :placeholder="translate('calculation_tool_data.purchase.manually_add.certified_organisation_placeholder')"
                                    dusk="btn-multiselect-certified-organisation"
                                    @update:modelValue="validateRequiredFields()"
                                />

                                <span
                                    v-if="errors.kvk"
                                    class="text-sm text-red-500"
                                    role="alert"
                                    v-text="errors.kvk"
                                />
                            </div>
                        </div>
                        <div class="mb-6">
                            <label
                                class="block mb-2 text-lg text-pso-dark"
                                dusk="label-value-of-purchase"
                                for="label-value-of-purchase"
                            >
                                {{ translate('calculation_tool_data.purchase.manually_add.value_of_purchase_label') }}
                            </label>

                            <input
                                id="label-value-of-purchase"
                                v-model="form.value_of_purchase"
                                :class="{'border-red-500': errors.value_of_purchase}"
                                :placeholder="translate('calculation_tool_data.purchase.manually_add.value_of_purchase_placeholder')"
                                autocomplete="value_of_purchase"
                                class="w-1/2 form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600 block"
                                name="value_of_purchase"
                                type="number"
                                step="0.01"
                                pattern="^\d*(\.\d{0,2})?$"
                                @keyup="validateRequiredFields"
                            >

                            <span
                                v-if="errors.value_of_purchase"
                                class="text-sm text-red-500"
                                role="alert"
                                v-text="errors.value_of_purchase"
                            />
                        </div>
                        <div class="mb-6 mt-8">
                            <button
                                :class="allRequiredAreFilled ? '' : 'opacity-50 cursor-not-allowed'"
                                class="flex items-center border-gray-400 bg-pso-orange border-1 rounded-xl p-4 pl-5 text-white"
                                dusk="btn-manually-save-target-group"
                                @click="savePurchase"
                            >
                                <img
                                    :src="iconPlusWhite"
                                    alt="open"
                                    class="w-4 mr-2"
                                >
                                <span
                                    class="text-white text-lg font-medium"
                                >
                                    {{
                                        translate('calculation_tool_data.purchase.manually_add.save_purchase_button')
                                    }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <section class="purchases-table-section pb-[22px] mb-10">
                    <div class="search-box-wapper flex justify-end items-center mb-2 relative">
                        <input
                            v-model="tableSearchValue"
                            :style="{ backgroundImage: 'url(' + iconMagnifier + ')' }"
                            class="border-gray-200 rounded-xl py-4 px-10 bg-no-repeat bg-[center_left_1rem] text-lg focus:outline-0 focus:border-gray-200 focus:shadow-none focus:ring-0"
                            type="text"
                        >
                        <button
                            v-if="tableSearchValue"
                            class="absolute right-4"
                            @click="clearSearchBox()"
                        >
                            <img
                                :src="iconClose"
                                alt="clear"
                            >
                        </button>
                    </div>
                    <EasyDataTable
                        :header-background-color="tableThemeColor"
                        :headers="tableHeaders"
                        :items="formattedPurchaseList"
                        v-model:items-selected="tableItemsSelected"
                        :rows-items="[10, 25, 50]"
                        :rows-per-page="10"
                        :search-field="tableSearchFields"
                        :search-value="tableSearchValue"
                        :theme-color="tableThemeColor"
                        buttons-pagination
                        header-font-color="white"
                        rows-per-page-message=""
                    >
                        <template #loading>
                            <img
                                :src="loadingImage"
                                alt=""
                            >
                        </template>

                        <template #item-value_of_purchase="item">
                            <div class="text-right">
                                {{ item.value_of_purchase }}
                            </div>
                        </template>
                    </EasyDataTable>
                    <div class="table-actions absolute -mt-7 flex">
                        <button
                            class="inline-flex items-center mb-2 border-pso-dark border rounded-xl p-4 pl-5 bg-white mr-2"
                            dusk="btn-edit-target-group-data"
                            @click.prevent="showEditModal()"
                        >
                            <img
                                :src="iconPencil"
                                alt="edit"
                                class="mr-2"
                            >
                            <span
                                class="text-gray-800 text-md font-medium"
                            >
                                {{ translate('calculation_tool_data.purchase.edit.edit_button') }}
                            </span>
                        </button>

                        <button
                            class="inline-flex items-center mb-2 border-pso-dark border rounded-xl p-4 pl-5 bg-white"
                            dusk="btn-delete-target-group-data"
                            @click.prevent="confirmDeleteAction()"
                        >
                            <img
                                :src="iconDelete"
                                alt="delete"
                                class="mr-2"
                            >
                            <span
                                class="text-gray-800 text-md font-medium"
                            >
                                {{ translate('calculation_tool_data.purchase.delete.delete_button') }}
                            </span>
                        </button>
                    </div>
                </section>

                <EditPurchaseModal />
                <ConfirmDialog group="templating">
                    <template #message>
                        <div class="flex flex-col">
                            <p class="mb-8">
                                {{ translate('calculation_tool_data.purchase.delete.confirmation_dialog.description') }}
                            </p>
                        </div>
                    </template>
                </ConfirmDialog>

                <section class="going-to-next-step">
                    <div class="mb-10">
                        <div class="pb-2 pt-2 flex items-center">
                            <input
                                id="check_box_1"
                                v-model="nextStepCheckboxChecked"
                                class="inline-block align-middle mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                                name="check_box_1"
                                type="checkbox"
                                value="1"
                            >
                            <label
                                class="text-md text-pso-dark"
                                for="check_box_1"
                            >
                                {{
                                    translate('calculation_tool_data.purchase.bottom_checkbox')
                                }}
                            </label>
                        </div>
                    </div>
                    <div class="actions-bottom">
                        <div class="action-button text-center">
                            <button
                                :class="nextStepCheckboxChecked ? '' : 'opacity-50 cursor-not-allowed'"
                                :disabled="!nextStepCheckboxChecked"
                                class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                                dusk="next-page-button"
                                @click="goToTheNextStep()"
                            >
                                {{
                                    translate('calculation_tool_data.purchase.button_go_next_step')
                                }}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CalculationTool from '../../../CalculationTool/CalculationTool';
import CalculationToolData from '../../CalculationToolData';
import CalculationToolHeader from '../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue';
import BackLinkButton from '../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue';
import ShowMoreElement from '../../../Templates/Parts/ShowMoreElement/Vue/ShowMoreElement.vue';
import {nl} from 'date-fns/locale';
import {useDropzone} from "vue3-dropzone";
import EasyDataTable from 'vue3-easy-data-table';
import ErrorAlert from '../../../Tools/Parts/Alert/Vue/ErrorAlert.vue';
import SuccessAlert from "../../../Tools/Parts/Alert/Vue/SuccessAlert.vue";
import dayjs from 'dayjs';
import Multiselect from '@vueform/multiselect';
import _ from "lodash";
import Links from "../../../_config/Links.js"
import {getSteps} from "../../../_config/Steps"
import FilledInDetailsBlock from "../../Parts/FilledInDetailsBlock.vue";
import EditPurchaseModal from "../../Parts/EditPurchaseModal/Vue/EditPurchaseModal.vue";
import ConfirmDialog from "primevue/confirmdialog";
import {useConfirm} from "primevue/useconfirm";
import {ref} from "vue";
import iconDelete from '@/images/icon_delete.svg';
import iconPencil from '@/images/icon_pencil.svg';
import iconUploadRound from '@/images/icon_upload_circled.svg';
import iconInfo from '@/images/icon_info.svg';
import iconPlusWhite from '@/images/icon_plus_white.svg';
import iconMagnifier from '@/images/icon_magnifier.svg';
import iconClose from '@/images/icon_times.svg';
import iconDownload from '@/images/icon_download.svg';
import loadingImage from '@/images/loading.svg';
import demoExcelFile from '@/files/inkoop_excel.xlsx?url';
import PurchasesUpload from "@/js/Services/PurchasesUpload";
import store from '../../../../Store/store.js';

const DEFAULT_FORM_VALUES = {
    product_name: '',
    kvk: null,
    value_of_purchase: '',
    organisationId: '',
};

export default {
    components: {
        ConfirmDialog, EditPurchaseModal,
        FilledInDetailsBlock,
        CalculationToolHeader,
        'app-back-button': BackLinkButton,
        'app-show-more': ShowMoreElement,
        ErrorAlert,
        SuccessAlert,
        EasyDataTable,
        Multiselect,
    },

    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }

    },
    setup(props) {
        const saveFiles = (files) => {
            PurchasesUpload.uploadPurchasesFile(files, props.calculationToolIdProp);
        };

        function onDrop(acceptFiles, rejectReasons) {
            if (acceptFiles.length > 0) {
                saveFiles(acceptFiles);
            }
            if (rejectReasons.length > 0) {
                window.mitt.emit("updateUploadStatus", {status: "error_headings_or_extension"});
            }
        }

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        return {
            nl,
            getRootProps,
            getInputProps,
            ...rest,
        };
    },
    data() {
        return {
            links: Links,
            form: Object.assign({}, DEFAULT_FORM_VALUES),
            confirm: useConfirm(),
            calculationToolData: {
                startDate: 'N/A',
                referenceDate: 'N/A',
                cao: 'N/A',
                employee: 'N/A',
                fte: 'N/A',
            },
            certifiedOrganisationsOptions: ref([]),
            certifiedOrganisationMapping: [],
            errors: {},
            showTab: 'manual-input',
            uploadStatus: '',
            demoExcelFile: demoExcelFile,
            tableSearchValue: '',
            tableThemeColor: '#3dbbf2',
            tableHeaders: [],
            tableItemsSelected: [],
            tableSearchFields: ['product_name', 'value_of_purchase', 'organisation.kvk', 'organisation.company_name'],
            nextStepCheckboxChecked: false,
            iconDelete: iconDelete,
            iconPencil: iconPencil,
            iconUploadRound: iconUploadRound,
            iconInfo: iconInfo,
            iconPlusWhite: iconPlusWhite,
            iconMagnifier: iconMagnifier,
            iconClose: iconClose,
            iconDownload: iconDownload,
            loadingImage: loadingImage,
            allRequiredAreFilled: false,
        };
    },

    computed: {
        ...mapGetters('calculation_tool_data', ['calculation_tool_data']),
        ...mapGetters('purchase', {purchasesList: 'purchase_list'}),
        ...mapGetters('calculation_tool', {certifiedOrganisationsList: 'certified_organisations_list'}),
        formattedPurchaseList() {
            return this.purchasesList.map(purchase => {
                return {
                    ...purchase,
                    value_of_purchase: '€' + purchase.value_of_purchase
                };
            });
        },
    },
    created() {
        window.mitt.on('updateUploadStatus', (data = {}) => {
            this.uploadStatus = data.status;
            if (data.status === 'success') {
                this.errors = [];
                window.mitt.emit('showSuccessAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.purchase.excel_upload_success'),
                        ],
                        'id': 'upload-excel',
                    });
            }
            if (data.message) {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            data.message
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                    });
            }
            if (data.status === 'error_headings_or_extension') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.invalid_extension_1'),
                            this.translate('calculation_tool_data.excel_upload_validations.invalid_extension_2'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                    });
            }


            if (data.status === 'server_error') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.server_error'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                    });
            }
            if (data.status === 'error_duplicates') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_duplicates_purchases'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                        'tableData': data.error,
                        'tableShow': 'duplicates'
                    });
            }
            if (data.status === 'error_duplicates_and_rows') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_rows'),
                        ],
                        'secondMessages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_duplicates_purchases'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                        'tableData': data.error,
                        'tableShow': 'duplicates_and_rows'
                    });
            }
            if (data.status === 'error_rows') {
                window.mitt.emit('showErrorAlert',
                    {
                        'messages': [
                            this.translate('calculation_tool_data.excel_upload_validations.error_rows'),
                        ],
                        'id': 'upload-excel',
                        'buttonMessage': this.translate('calculation_tool_data.excel_upload_validations.button_try_again'),
                        'buttonAction': 'show-upload',
                        'tableData': data.error,
                        'tableShow': 'rows'
                    });
            }
        });
    },
    mounted() {
        CalculationToolData.get(this.calculationToolIdProp).then(() => {
            this.calculationToolData.referenceDate = dayjs(this.calculation_tool_data.calculated.reference_date).format('DD-MM-YYYY');
            this.calculationToolData.startDate = dayjs(this.calculation_tool_data.calculated.start_date).format('DD-MM-YYYY');
            this.calculationToolData.cao = this.calculation_tool_data.calculated.cao_hours;
            this.calculationToolData.fte = this.calculation_tool_data.calculated.total_fte;
            this.calculationToolData.employee = this.calculation_tool_data.calculated.total_employee;
        });
        CalculationTool.get(this.calculationToolIdProp);
        CalculationToolData.getPurchaseList(this.calculationToolIdProp);
        this.setTableHeaders();
        CalculationTool.getCertifiedOrganisations(this.calculationToolIdProp).then(() => {
            for (const branchName in this.certifiedOrganisationsList) {
                const organisations = this.certifiedOrganisationsList[branchName];
                organisations.forEach(certifiedOrganisation => {
                    // Push an object with organisation_id, certification_id, and kvk to the array
                    this.certifiedOrganisationMapping.push({
                        organisation_id: certifiedOrganisation.organisation_id,
                        certification_id: certifiedOrganisation.certification.id,
                        kvk: certifiedOrganisation.kvk
                    });
                });
                const group = {
                    label: branchName,
                    options: organisations.map(certifiedOrganisation => ({
                        value: Number(certifiedOrganisation.organisation_id),
                        label: certifiedOrganisation.company_name + ' - ' + certifiedOrganisation.kvk
                    }))
                };
                this.certifiedOrganisationsOptions.push(group)
            }
        });
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-data.target-group-data', { calculationTool: this.calculationToolIdProp });
        },
        switchTabs(tab) {
            this.showTab = tab;
            this.resetUpload();
        },
        async downloadCertifiedOrganisations() {
            const response = await store.dispatch('purchase/downloadCertifiedOrganisations');
            const file = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            // Create a temporary link to download the file
            const url = window.URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'PSO organisaties.xlsx'; // Specify the filename
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        },
        resetUpload() {
            window.mitt.emit('showErrorAlert',
                {
                    'id': 'upload-excel',
                });
            this.uploadStatus = '';
        },
        setTableHeaders() {
            this.tableHeaders = [
                {
                    text: this.translate('calculation_tool_data.purchase.purchase_table.purchased'),
                    value: "product_name",
                    sortable: true,
                },
                {
                    text: this.translate('calculation_tool_data.purchase.purchase_table.value'),
                    value: "value_of_purchase",
                    sortable: true,
                },
                {
                    text: this.translate('calculation_tool_data.purchase.purchase_table.kvk_number'),
                    value: "organisation.kvk",
                    sortable: true,
                },
                {
                    text: this.translate('calculation_tool_data.purchase.purchase_table.company_name'),
                    value: "organisation.company_name",
                    sortable: true,
                },
            ];
        },
        clearSearchBox() {
            this.tableSearchValue = '';
        },
        showEditModal() {
            if (this.tableItemsSelected.length === 0) {
                return;
            }

            let modalData = {
                selectedItemsToEdit: this.tableItemsSelected,
                calculationToolId: this.calculationToolIdProp,
                calculationToolData: this.calculationToolData,
                certifiedOrganisationsOptions: this.certifiedOrganisationsOptions,
                certifiedOrganisationMapping: this.certifiedOrganisationMapping,
            };

            window.mitt.emit('showPurchaseEditModal', modalData);
        },
        confirmDeleteAction() {
            let itemsToDelete = _.map(this.tableItemsSelected, 'id');

            if (itemsToDelete.length === 0) {
                return;
            }

            this.confirm.require({
                group: 'templating',
                header: this.translate('calculation_tool_data.purchase.delete.confirmation_dialog.title'),
                message: this.translate('calculation_tool_data.purchase.delete.confirmation_dialog.description'),
                acceptLabel: this.translate('calculation_tool_data.purchase.delete.confirmation_dialog.confirm_button_text'),
                rejectLabel: this.translate('calculation_tool_data.purchase.delete.confirmation_dialog.cancel_button_text'),

                defaultFocus: false,
                accept: () => {
                    this.deletePurchases({
                        actionType: 'delete',
                        entityData: itemsToDelete,
                    });
                },
            });
        },
        deletePurchases(actionData) {
            let itemsToDelete = _.map(this.tableItemsSelected, 'id');
            if (actionData.actionType !== 'delete' || !_.isEqual(Object.assign({}, itemsToDelete), Object.assign({}, actionData.entityData))) {
                return;
            }

            CalculationToolData.deleteMultiplePurchases(this.calculationToolIdProp, itemsToDelete)
                .then(() => {
                    this.errors.value = '';
                    this.tableItemsSelected = [];
                })
                .catch((error) => {
                    if (error) {
                        this.errors.value = error.response.data.message ?? error;
                    }
                });
        },
        goToTheNextStep() {
            if (this.nextStepCheckboxChecked) {
                CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().score})
                    .then((response) => {
                        if (response.status === 200) {
                            window.location = route('score.index', { calculationTool: this.calculationToolIdProp });
                        }
                    }).catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            }
        },
        validateRequiredFields() {
            let requiredKeys = ['product_name', 'organisationId', 'value_of_purchase'];
            this.allRequiredAreFilled = !requiredKeys.some(function (key) {
                return !Boolean(this.form[key]);
            }.bind(this));
        },
        clearForm() {
            this.errors = [];
            this.form = Object.assign({}, DEFAULT_FORM_VALUES);
        },
        findKvkById(list, id) {

        },
        savePurchase() {
            if (this.allRequiredAreFilled === true) {
                let requestObject = {
                    organisation_id: this.form.organisationId,
                    product_name: this.form.product_name,
                    supplier_certification: this.getOrganisationDetailsByOrganisationId(this.form.organisationId).certification_id,
                    kvk: this.getOrganisationDetailsByOrganisationId(this.form.organisationId).kvk,
                    value_of_purchase: this.form.value_of_purchase
                };

                CalculationToolData.createPurchase(this.calculationToolIdProp, requestObject)
                    .then(() => {
                        this.clearForm();
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            let kvkErrors = ((((error || {}).response || {}).data || {}).errors || {}).kvk;
                            if (kvkErrors && kvkErrors.includes('trigger_same_exact_purchase_exists')) {
                                delete error.response.data.errors.kvk;
                                let dialogData = {
                                    header: this.translate('calculation_tool_data.purchase.manually_add.modal_unique_purchase_title'),
                                    body: this.translate('calculation_tool_data.purchase.manually_add.modal_unique_purchase_body'),
                                    buttons: [{
                                        text: this.translate('calculation_tool_data.purchase.manually_add.modal_unique_purchase_button'),
                                        redirect: '#label-product-name',
                                    }],
                                    closable: true,
                                }

                                window.mitt.emit("dialogMessage", dialogData);
                            }
                        }
                        if (error.response) {
                            this.$setErrorsFromResponse(error.response.data);
                        }
                    });
            }
        },
        getOrganisationDetailsByOrganisationId(organisationId) {
            // Find the entry with the matching OrganisationId
            const entry = this.certifiedOrganisationMapping.find(item => item.organisation_id === organisationId);
            return entry ? { kvk: entry.kvk, certification_id: entry.certification_id } : null;
        },
    }
};
</script>
