<template>
    <div class="page auditor-dashboard">
        <div class="container mx-auto">
            <div class="max-w-[1108px]  mx-auto pb-12">
                <div class="page-header text-blue-1000 font-bold text-3xl">
                    {{ translate('dashboard.auditor.dashboard.title') }}
                </div>
            </div>
            <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold">
                    {{ translate('dashboard.auditor.dashboard.subtitle') }}
                </h2>
                <section class="purchases-table-section pb-[22px] my-10">
                    <div class="search-box-wapper flex justify-end items-center mb-2 relative">
                        <input
                            v-model="tableSearchValue"
                            :style="{ backgroundImage: 'url(' + iconMagnifier + ')' }"
                            class="border-gray-200 rounded-xl py-4 px-10 bg-no-repeat bg-[center_left_1rem] text-lg focus:outline-0 focus:border-gray-200 focus:shadow-none focus:ring-0"
                            type="text"
                        >
                        <button
                            v-if="tableSearchValue"
                            class="absolute right-4"
                            @click="clearSearchBox()"
                        >
                            <img
                                :src="iconClose"
                                alt="clear"
                            >
                        </button>
                    </div>
                    <EasyDataTable
                        id="easy-data-table"
                        :header-background-color="tableThemeColor"
                        :headers="tableHeaders"
                        :items="calculationToolsList"
                        :items-selected="tableItemsSelected"
                        :rows-items="[10, 25, 50]"
                        :rows-per-page="10"
                        :search-field="tableSearchFields"
                        :search-value="tableSearchValue"
                        :theme-color="tableThemeColor"
                        buttons-pagination
                        header-font-color="white"
                        rows-per-page-message=""
                    >
                        <template #loading>
                            <img
                                :src="loadingImage"
                                alt=""
                            >
                        </template>
                    </EasyDataTable>
                </section>


                <div class="action-button text-center">
                    <button
                        :class="selectedCalculationToolId === null ? 'bg-grayish-blue cursor-default' : 'bg-blue-1000 cursor-pointer'"
                        :disabled="selectedCalculationToolId === null"
                        class="bg-blue-1000 text-white py-3 px-8 rounded-xl font-medium inline-block"
                        dusk="btn-auditor-dashboard-continue"
                        @click="nextStep()"
                    >
                        {{ translate('dashboard.auditor.dashboard.continue') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import EasyDataTable from "vue3-easy-data-table";
import CalculationTool from "../../../../CalculationTool/CalculationTool";

export default {
    components: {
        EasyDataTable,
    },
    data() {
        return {
            tableThemeColor: '#3dbbf2',
            tableSearchValue: '',
            tableHeaders: [],
            tableItemsSelected: [],
            selectedCalculationToolId: null,
            selectedCalculationToolOrganisationId: null,
            tableSearchFields: ['organisations.0.company_name', 'organisations.0.city', 'audit_date', 'recertification'],
            loadingImage: new URL('/resources/images/loading.svg', import.meta.url).href,
            iconMagnifier: new URL('/resources/images/icon_magnifier.svg', import.meta.url).href,
            iconClose: new URL('/resources/images/icon_times.svg', import.meta.url).href,
        }
    },
    computed: {
        ...mapGetters('calculation_tool', {calculationToolsList: 'auditor_dashboard_data'})
    },
    watch: {
        tableItemsSelected: {
            deep: true,
            handler(value) {
                if (value.length > 1) {
                    this.tableItemsSelected.pop();
                }

                this.selectedCalculationToolOrganisationId = this.tableItemsSelected[0].organisations[0]?.id;
                this.selectedCalculationToolId = this.tableItemsSelected[0]?.id;
            }
        },
    },
    mounted() {
        CalculationTool.getAuditorDashboardData();
        this.setTableHeaders();
    },
    methods: {
        nextStep() {
            window.location = route('auditor.dashboard', {
                'calculationTool': this.selectedCalculationToolId,
                'organisation': this.selectedCalculationToolOrganisationId,
            });
        },
        clearSearchBox() {
            this.tableSearchValue = '';
        },
        setTableHeaders() {
            this.tableHeaders = [
                {
                    text: this.translate('dashboard.auditor.dashboard.auditor_table.company_name'),
                    value: "organisations.0.company_name",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.auditor.dashboard.auditor_table.city'),
                    value: "organisations.0.city",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.auditor.dashboard.auditor_table.audit_date'),
                    value: "audit_date",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.auditor.dashboard.auditor_table.auditor'),
                    value: "auditors.0.full_name_informal",
                    sortable: true,
                },
                {
                    text: this.translate('dashboard.auditor.dashboard.auditor_table.recertification'),
                    value: "recertification",
                    sortable: true,
                },
            ];
        },
    }
};
</script>

<style scoped>
    #easy-data-table :deep(th:first-child) div {
        display: none;
    }
</style>
