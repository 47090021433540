import axios from 'axios';

export default {
    namespaced: true,
    state: {
        target_group_employee_list: [],
        target_group_employee_sampling_list: [],
        contract_type: [],
        starting_position: [],
    },
    getters: {
        target_group_employee_list(state) {
            return state.target_group_employee_list;
        },
        target_group_employee_sampling_list(state) {
            return state.target_group_employee_sampling_list;
        },
        contract_type(state) {
            return state.contract_type;
        },
        starting_position(state) {
            return state.starting_position;
        },
    },
    mutations: {
        target_group_employee_list(state, data) {
            state.target_group_employee_list = data;
        },
        target_group_employee_sampling_list(state, data) {
            state.target_group_employee_sampling_list = data;
        },
        contract_type(state, data) {
            state.contract_type = data;
        },
        starting_position(state, data) {
            state.starting_position = data;
        },
    },
    actions: {
        create({dispatch}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/target-group', data.payload)
                .then((response) => dispatch("addTargetGroupEmployeeToList", response.data.data));
        },
        update({}, data) {
            return axios.patch('/api/calculation-tools/' + data.calculationToolId + '/target-group/' + data.targetGroupEmployeeId, data.payload)
                .then((response) => response);
        },
        update_sample({}, data) {
            return axios.patch('/api/calculation-tools/' + data.calculationToolId + '/target-group/sample/' + data.targetGroupEmployeeId, data.payload)
                .then((response) => response);
        },
        getContractType({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/target-group/options-employee-contract-type')
                .then((response) => commit('contract_type', response.data.data));
        },
        getStartingPosition({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/target-group/options-employee-starting-position')
                .then((response) => commit('starting_position', response.data.data));
        },
        getTargetGroupEmployeeList({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/target-group')
                .then((response) => commit('target_group_employee_list', response.data.data));
        },
        getOriginalTargetGroupEmployeeList({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/target-group/original')
                .then((response) => commit('target_group_employee_list', response.data.data));
        },
        getTargetGroupEmployeeSamplesList({commit}, calculationToolId) {
            return axios.get('/api/calculation-tools/' + calculationToolId + '/target-group/sampling/')
                .then((response) => commit('target_group_employee_sampling_list', response.data.data));
        },
        getScoringStats({commit}, payload) {

            let version = payload.version ?? 'original';
            return axios.get('/api/calculation-tools/' + payload.id + '/target-group/scoring-stats/' + version)
                .then((response) => response);
        },
        addTargetGroupEmployeeToList({commit, state}, target_employee) {
            let target_group_employee_list = state.target_group_employee_list;
            target_group_employee_list[state.target_group_employee_list.length] = target_employee;
            commit('target_group_employee_list', target_group_employee_list);
        },
        upload({commit}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + `/target-group/upload?isAuditor=${data.isAuditor ? 1 : 0}`, data.payload)
                .then((response) => commit('target_group_employee_list', response.data.data));
        },
        deleteMultiple({commit}, data) {
            return axios.delete('/api/calculation-tools/' + data.calculationToolId + '/target-group/delete-multiple', { data : { ids: data.payload }})
                .then((response) => commit('target_group_employee_list', response.data.data));
        },

        auditorDeleteMultiple({commit}, data) {
            return axios.delete('/api/calculation-tools/' + data.calculationToolId + '/target-group/delete-multiple', {
                data: {
                    ids: data.payload,
                    auditorMessage: data.auditorMessage
                },
            }).then((response) => commit('target_group_employee_list', response.data.data));
        },
        auditorStoreSamples({commit}, data) {
            return axios.post('/api/calculation-tools/' + data.calculationToolId + '/target-group/sampling/store', {
                data: {
                    ids: data.payload,
                },
            }).then((response) => commit('target_group_employee_sampling_list', response.data.data));
        },
        auditorDeleteMultipleSamples({commit}, data) {
            return axios.delete('/api/calculation-tools/' + data.calculationToolId + '/target-group/sampling/delete-multiple', {
                data: {
                    ids: data.payload,
                },
            }).then((response) => commit('target_group_employee_sampling_list', response.data.data));
        },
    }
};
